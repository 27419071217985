@import url(https://fonts.googleapis.com/css?family=Bad+Script);

@mixin keyframes($name, $duration, $count) {
	@keyframes love1 {
		0% {
			top: 150px;
		}
		20% {
			left: 10px;
		}
		40% {
			left: 0px;
		}
		60% {
			left: 10px;
		}
		80% {
			left: 0px;
		}
		100% {
			top: 10px;
			left: 5px;
			opacity: 0;
		}
	}

	@keyframes love2 {
		0% {
			top: 150px;
		}
		20% {
			left: 30px;
		}
		40% {
			left: 20px;
		}
		60% {
			left: 30px;
		}
		80% {
			left: 20px;
		}
		100% {
			top: 30px;
			left: 5px;
			opacity: 0;
		}
	}

	@keyframes love3 {
		0% {
			top: 150px;
		}
		20% {
			left: 60px;
		}
		40% {
			left: 50px;
		}
		60% {
			left: 60px;
		}
		80% {
			left: 50px;
		}
		100% {
			top: 60px;
			left: 5px;
			opacity: 0;
		}
	}

	@keyframes love4 {
		0% {
			top: 150px;
		}
		20% {
			left: 90px;
		}
		40% {
			left: 80px;
		}
		60% {
			left: 90px;
		}
		80% {
			left: 80px;
		}
		100% {
			top: 90px;
			left: 5px;
			opacity: 0;
		}
	}
	@keyframes love5 {
		0% {
			top: 150px;
		}
		20% {
			left: 110px;
		}
		40% {
			left: 120px;
		}
		60% {
			left: 110px;
		}
		80% {
			left: 115px;
		}
		100% {
			top: 100px;
			left: 5px;
			opacity: 0;
		}
	}
	animation-name: $name;
	animation-duration: $duration;
	animation-iteration-count: $count;
}
h1 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: "Bad Script";
	color: #de4747;
	text-align: center;
	width: 100%;
}
.heartcont {
	position: absolute;
	top: 45%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 20em;
	width: 10em;
}
.alllove {
	position: relative;
	margin: 0 auto;
	background-color: #f5eee6;
	width: 100vw;
	height: 100vh;
}

.love {
	position: absolute;
	left: 0;
	color: #de4747;
}

.love1 {
	@include keyframes(love1, 5s, infinite);
}

.love2 {
	left: 30px;
	@include keyframes(love2, 6s, infinite);
}

.love3 {
	left: 60px;
	@include keyframes(love3, 4s, infinite);
}

.love4 {
	left: 80px;
	@include keyframes(love4, 4s, infinite);
}

.love5 {
	left: 100px;
	@include keyframes(love5, 5s, infinite);
}
