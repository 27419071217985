.image-grid {
	position: relative;
	width: 100vw; // Chiều rộng bằng với chiều rộng của viewport
	height: 100vh; // Chiều cao bằng với chiều cao của viewport
	display: grid;
	grid-template-columns: repeat(50, 1fr);
	grid-template-rows: repeat(40, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 5px;
	background-color: #f5eee6;
	@media (max-width: 910px) {
		display: block;
	}
}

.btn-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.image-item {
	&:nth-child(1) {
		grid-area: 10/5/14/14;
	}
	&:nth-child(2) {
		grid-area: 5/14/17/22;
	}

	&:nth-child(3) {
		grid-area: 19/13/38/20;
	}
	&:nth-child(4) {
		grid-area: 6/37/35/47;
	}

	&:nth-child(5) {
		grid-area: 6/31/22/37;
	}
	&:nth-child(6) {
		grid-area: 26/29/37/41;
	}
	@media (max-width: 910px) {
		position: absolute;

		&:nth-child(1) {
			top: 4%;
			left: 44%;
			width: 47%;
		}

		&:nth-child(2) {
			top: 29%;
			left: 10%;
			width: 35%;
		}

		&:nth-child(3) {
			top: 3%;
			left: 12%;
			width: 29%;
		}
		&:nth-child(4) {
			top: 56%;
			left: 10%;
			width: 48%;
		}
		&:nth-child(5) {
			top: 72%;
			left: 55%;
			width: 32%;
		}
		&:nth-child(6) {
			top: 57%;
			left: 42%;
			width: 50%;
		}
	}
}

img {
	pointer-events: none;
	width: 100%;
	height: auto;
	display: block;
	border-radius: 8px; // Bo góc cho hình ảnh
	box-shadow: 7px 7px 0px 0px #f3d7ca; // Đổ bóng cho hình ảnh
}
