.bodyvalentine {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	height: 100vh;
	width: 100vw;
	background-color: #f5eee6;
	@media (max-width: 1150px) {
		display: block;
		position: relative;
	}
}
.imagebanner {
	img {
		width: auto;
	}
	overflow: hidden;
	width: 100%;
	height: 100%;
	grid-area: 1 / 1 / 2 / 5;

	@media (max-width: 1150px) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		filter: brightness(33%);
	}
}

.videobanner {
	border-radius: 0;
	height: 100vh;
}

.textmess {
	grid-area: 1 / 5 / 2 / 11;
	position: relative;
	width: 50vw;
	height: auto;
	@media (max-width: 1150px) {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.textcontan {
	color: #e6a4b4;
	margin: 20px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	@media (max-width: 1150px) {
		margin: 0;
	}
}
