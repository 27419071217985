.btnValentine {
	appearance: button;
	background-color: #E6A4B4;
	background-image: none;
	border: 1px solid #E6A4B4;
	border-radius: 4px;
	box-shadow: #fff 4px 4px 0 0, #E6A4B4 4px 4px 0 1px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family:  "Lobster", cursive;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin: 0 5px 10px 0;
	overflow: visible;
	padding: 12px 40px;
	text-align: center;
	text-transform: none;
	touch-action: manipulation;
	user-select: none;
	-webkit-user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	&:focus {
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;
	}

	&:active {
		box-shadow: #e6a4b51b 0 3px 5px inset;
		outline: 0;
	}

	&:not([disabled]):active {
		box-shadow: #fff 2px 2px 0 0, #F3D7CA 2px 2px 0 1px;
		transform: translate(2px, 2px);
	}

	@media (min-width: 1150px) {
		padding: 12px 50px;
	}
}
